import Image from 'next/image';
import { ButtonLink } from 'components/buttons/button-link';
import { PrescriptionType } from 'types/product';
import { LanguageCodesType, RegionCodesType } from 'utils';

export const SubmitButton = ({
  content,
  language,
  region,
}: {
  content: PrescriptionType;
  language: LanguageCodesType;
  region: RegionCodesType;
}) => {
  if (!content) return null;

  return (
    <ButtonLink
      className='px-4 lg:h-[52px]'
      href={content.data?.cta_button_submit_prescription?.link}
      target={
        content.data?.cta_button_consult_doctor?.link_type === 'External'
          ? '_blank'
          : 'self'
      }
      style={{
        backgroundColor:
          content?.data?.color_theme
            ?.cta_button_submit_prescription_background_color,
        color:
          content?.data?.color_theme?.cta_button_submit_prescription_text_color,
      }}
    >
      <div className='flex items-center justify-center lg:justify-start lg:space-x-3 xl:space-x-6 rtl:space-x-reverse'>
        <Image
          src={
            content.data?.cta_button_submit_prescription
              ?.submit_presceription_icon
          }
          alt={content.data?.cta_button_submit_prescription?.label[language]}
          width={0}
          height={0}
          sizes='100vw'
          className='hidden h-[26px] w-[22px] lg:block'
        />
        <span className='text-custom-sm lg:text-custom-base'>
          {content.data?.cta_button_submit_prescription?.label[language]}
        </span>
      </div>
    </ButtonLink>
  );
};
