'use client';

import { useEffect, useState } from 'react';

import { getProductsByProductIds } from 'lib/api';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  setAlternativeModal,
  setAlternativeProducts,
} from 'redux/features/alternative-modal';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import SecondaryOptionModal from 'components/secondary-option-modal';
import ProductCarousel from 'components/cms/banners/product-carousel-banner/product-carousel';
import { useTranslation } from 'app/i18n/client';
import BannerLoadingSkeleton from 'components/banner-loading-skeleton';

export default function AlternativeModal({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const appDispatch = useAppDispatch();
  const { modalOpen, alternativeProducts, storeZoneId } = useAppSelector(
    (state) => state.alternativeModalReducer
  );
  const [products, setProducts] = useState([]);
  const { ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'products'
  );

  useEffect(() => {
    if (modalOpen && alternativeProducts?.length > 0) {
      const productsData = async () => {
        try {
          const ids = await getProductsByProductIds(
            alternativeProducts.join(','),
            language,
            region
          );
          setProducts(ids as any);
        } catch (error) {
          console.error('Failed to fetch products:', error);
        }
      };
      productsData();
    } else {
      setProducts([]);
    }
  }, [alternativeProducts, language, modalOpen, region]);

  return (
    <SecondaryOptionModal
      modalId='alternative-modal'
      open={modalOpen}
      setOpen={(v: boolean) => appDispatch(setAlternativeModal(v))}
      title={ts('choose_alternative')}
      modalWidth='w-[auto] lg:w-[960px]'
      dynamicModalCloseHandler={() => appDispatch(setAlternativeProducts([]))}
      modalClassname='z-[97] items-center overflow-visible'
      containerClassName='overflow-visible'
      backgroundBlurClassName='z-[97]'
      language={language}
      region={region}
    >
      {products?.length > 0 ? (
        <div className='grid max-h-[550px] grid-cols-2 gap-2 overflow-y-auto max-lg:my-4 lg:h-[75vh] lg:max-h-[700px] lg:grid-cols-4 lg:gap-4'>
          <ProductCarousel
            products={products}
            swiper={false}
            componentIndex={1001}
            language={language}
            region={region}
            storeZoneId={storeZoneId}
          />
        </div>
      ) : (
        <BannerLoadingSkeleton className='!h-[300px] max-md:mt-2 lg:h-[350px]' />
      )}
    </SecondaryOptionModal>
  );
}
