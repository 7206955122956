import Image from 'next/image';
import { ButtonLink } from 'components/buttons/button-link';
import { LanguageCodesType, RegionCodesType } from 'utils';

export const ConsultButton = ({
  content,
  language,
  region,
}: {
  content: any;
  language: LanguageCodesType;
  region: RegionCodesType;
}) => {
  return (
    <ButtonLink
      appearance='outline'
      className='px-4 hover:border-blue hover:bg-white hover:text-blue lg:h-[52px]'
      href={content.data?.cta_button_consult_doctor?.link}
      target={
        content.data?.cta_button_consult_doctor?.link_type === 'External'
          ? '_blank'
          : 'self'
      }
    >
      <div className='flex items-center justify-center lg:justify-start lg:space-x-3 xl:space-x-6 rtl:space-x-reverse'>
        <Image
          src={content.data?.consult_doctor_icon}
          alt={content.data?.cta_button_consult_doctor?.label[language]}
          width={0}
          height={0}
          sizes='100vw'
          className='hidden h-[26px] w-[22px] lg:block'
        />
        <span className='text-custom-sm lg:text-custom-base'>
          {content.data?.cta_button_consult_doctor?.label[language]}
        </span>
      </div>
    </ButtonLink>
  );
};
