/**
 * @description More detailed error handling to help improve sentry reporting and exception handlig within App.
 * @example throw new RequestError(message, statusCode);
 * @example we can check error types now i.e (error instanceof RequestError)
 */
export class RequestError extends Error {
  date: string;

  /**
   *
   * @param message //error message
   * @param statusCode Request status code
   * @param url URL where request failed
   * @param reqMetaData could include request body, headers and other additional data for logging
   */
  constructor(
    public message: string,
    public statusCode: number,
    public url: string,
    public reqMetaData?: Record<string, unknown>
  ) {
    super(message);
    this.name = 'RequestError';
    this.date = new Date().toUTCString();
  }
}
