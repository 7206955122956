'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useTranslation } from 'app/i18n/client';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  setBundleModal,
  setBundleProductInfo,
} from 'redux/features/bundle-products';

import SecondaryOptionModal from 'components/secondary-option-modal';
import BundleProductCarouselItem from 'components/cms/banners/product-carousel-banner/bundle-product-item';
import BundleItem from 'components/product/bundle-offer/bundle-item';
import { BundleAddCart } from 'components/product/bundle-offer/add-to-cart';
import { getProductsByProductIds } from 'lib/api';
import { ProductItemType } from 'types/cms/product-carousel';
import { useCookies } from 'react-cookie';
import { getBundleProduct } from 'lib/nahdi-middleware/product';
import { BundleProductInfoType } from 'types/product';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { COOKIE_ZONE_ID } from 'utils/constant-cookies';

export default function BundleModal({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const appDispatch = useAppDispatch();
  const { modalOpen, productInfo } = useAppSelector(
    (state) => state.bundleModalReducer
  );
  const { appSettings } = useAppSelector((state) => state.cmsReducer);
  const maxQuantityLimit =
    appSettings?.global_components?.basket?.max_qty_per_sku;
  const [product, setProduct] = useState<ProductItemType>();
  const [bundleData, setBundleData] = useState<BundleProductInfoType>();
  const { t, ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'product-list-page'
  );
  const [cookies] = useCookies([COOKIE_ZONE_ID]);
  const zoneIdCookie = cookies?.zoneId || '';

  useEffect(() => {
    if (modalOpen) {
      const productsData = async () => {
        const mainProduct = await getProductsByProductIds(
          productInfo?.id,
          language,
          region,
          zoneIdCookie,
          false,
          false
        );
        const bundleData = (await getBundleProduct(
          productInfo?.id,
          language,
          region
        )) as BundleProductInfoType;
        setProduct(mainProduct?.[0]);
        setBundleData(bundleData);
      };
      productsData();
    } else {
      setProduct(undefined);
      setBundleData(undefined);
    }
  }, [language, modalOpen, productInfo?.id, region, zoneIdCookie]);

  return (
    <SecondaryOptionModal
      modalId='bundle-modal'
      open={modalOpen}
      setOpen={(v: boolean) => appDispatch(setBundleModal(v))}
      title={bundleData?.title || ts('bundle_modal.title')}
      modalWidth='w-[auto] md:w-[496px]'
      contentClassName='bg-light-gray-200'
      dynamicModalCloseHandler={() =>
        appDispatch(
          setBundleProductInfo({
            id: '',
            rates: {
              avgRating: 0,
              totalRatings: 0,
            },
          })
        )
      }
      modalClassname='z-[98]'
      backgroundBlurClassName='z-[98]'
      language={language}
      region={region}
    >
      <div className='my-4 lg:my-0'>
        <BundleProductCarouselItem
          product={product}
          rates={productInfo?.rates}
          maxQuantityLimit={maxQuantityLimit}
          language={language}
          region={region}
        />

        {bundleData && bundleData?.items?.length > 0 && (
          <div className='relative mt-6'>
            <h2 className='mb-4 text-lg lg:text-xl'>
              {bundleData?.ga_title || t('bundle_modal.bundle_offer')}
            </h2>
            <div className='relative rounded-lg bg-white p-4'>
              <div className='absolute z-10'>
                <Image
                  src='/assets/images/product_tags/bundle_offer.png'
                  alt={'bundle product'}
                  width={0}
                  height={0}
                  sizes='100vw'
                  className='h-[30px] w-[85px]'
                />
              </div>
              <BundleItem
                bundle={bundleData}
                currency={product?.attributes?.currency || ''}
                titleClassName='h-auto ltr:text-center rtl:text-center'
                priceClassName='ltr:text-center rtl:text-center'
                language={language}
                region={region}
              />
              <div className='absolute bottom-3 ltr:right-3 rtl:left-3'>
                <BundleAddCart
                  products={bundleData?.items}
                  buttonTypePlus
                  language={language}
                  region={region}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </SecondaryOptionModal>
  );
}
