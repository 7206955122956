export const REVALIDATE = {
  oneMinute_60: 60,
  threeMinutes_180: 180,
  fiveMinutes_300: 300,
  tenMinutes_600: 600,
  fifteenMinutes_900: 900,
  thirtyMinutes_1800: 1800,
  oneHour_3600: 3600,
  fourHours_14400: 14400,
  oneDay_86400: 86400,
};

export const TAGS = {
  product: 'product',
  products: 'products',
  content: 'content',
  cart: 'cart',
};
