import { fetchCacheConverter } from './cloudflare';
import { extractExceptionMessage } from './map-exception';
import { RequestError } from './request-error';

interface HttpParam {
  body?: BodyInit | null;
  url: string;
  prefix?: string;
  params?: Record<string, unknown>;
  headers?: HeadersInit | undefined;
  signal?: AbortSignal;
  responseFormat?: 'json' | 'text';
  cache?: boolean;
  revalidate?: number;
}
/**
 * @param params An object you wish to convert into a query string, could be deeply nested. Algorithm supports deep nesting
 * @param prefix //Technically used to support orderliness in deeply nested structure
 * @returns string
 */
export function generateQueryParamsString(
  params: Record<string, unknown>,
  prefix?: string
): string {
  const queryStringParts = [];
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];
      const encodedKey = prefix
        ? `${prefix}[${encodeURIComponent(key)}]`
        : encodeURIComponent(key);
      if (typeof value === 'object' && value !== null) {
        // Recursively call for nested objects or arrays
        queryStringParts.push(
          generateQueryParamsString(
            value as Record<string, unknown>,
            encodedKey
          )
        );
      } else {
        queryStringParts.push(
          `${encodedKey}=${encodeURIComponent(value as never)}`
        );
      }
    }
  }
  return queryStringParts.join('&');
}
export class HttpConnection {
  static async get<T>({
    url,
    params = {},
    headers = {},
    prefix = '?',
    signal,
    cache,
    revalidate,
  }: HttpParam): Promise<T> {
    const queryString = generateQueryParamsString(params);
    const reqUrl = url + prefix + queryString;
    const fetchCache = fetchCacheConverter({ cache, revalidate });
    const result = await fetch(reqUrl, {
      method: 'GET',
      headers: headers,
      signal,
      ...fetchCache,
    });
    const data = await result.json();
    if (!result?.ok) {
      const message = extractExceptionMessage(data);
      throw new RequestError(message, result.status, reqUrl);
    }
    return data as T;
  }
  static async post<T>({
    url,
    params = {},
    headers = {},
    prefix = '?',
    body,
    signal,
  }: HttpParam): Promise<T> {
    const queryString = generateQueryParamsString(params);
    const reqUrl = url + prefix + queryString;
    const result = await fetch(reqUrl, {
      body,
      method: 'POST',
      headers,
      signal,
    });
    const data = await result.json();
    if (!result?.ok) {
      const message = extractExceptionMessage(data);
      throw new RequestError(message, result.status, reqUrl);
    }
    return data as T;
  }

  static async put<T>({
    url,
    params = {},
    headers = {},
    prefix = '?',
    body,
    signal,
    cache,
    revalidate,
    responseFormat = 'json',
  }: HttpParam): Promise<T> {
    const queryString = generateQueryParamsString(params);
    const reqUrl = url + prefix + queryString;
    const fetchCache = fetchCacheConverter({ cache, revalidate });
    const result = await fetch(reqUrl, {
      body,
      method: 'PUT',
      headers,
      signal,
      ...fetchCache,
    });

    const data =
      responseFormat == 'json' ? await result.json() : await result.text();
    if (!result?.ok) {
      const message = extractExceptionMessage(data);
      throw new RequestError(message, result.status, reqUrl);
    }

    return data as T;
  }

  static async delete<T>({
    url,
    params = {},
    headers = {},
    prefix = '?',
    signal,
    cache,
    revalidate,
  }: HttpParam): Promise<T> {
    const queryString = generateQueryParamsString(params);
    const reqUrl = url + prefix + queryString;
    const fetchCache = fetchCacheConverter({ cache, revalidate });
    const result = await fetch(reqUrl, {
      method: 'DELETE',
      headers: headers,
      signal,
      ...fetchCache,
    });
    const data = await result.json();
    if (!result?.ok) {
      const message = extractExceptionMessage(data);
      throw new RequestError(message, result.status, reqUrl);
    }
    return data as T;
  }
}
