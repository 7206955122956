'use client';

import Image from 'next/image';
import SecondaryOptionModal from 'components/secondary-option-modal';
import { WarningIcon } from 'components/icon';
import { SubmitButton } from './submit-button';
import { ConsultButton } from './consult-button';
import { PrescriptionType } from 'types/product';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setPresceriptionModal } from 'redux/features/presceription-modal';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function PrescriptionModal({
  content,
  language,
  region,
}: {
  content: PrescriptionType | undefined;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const appDispatch = useAppDispatch();
  const { modalOpen } = useAppSelector(
    (state) => state.presceriptionModalReducer
  );

  if (!content) return null;

  return (
    <SecondaryOptionModal
      modalId='prescription-modal'
      open={modalOpen}
      setOpen={(v: boolean) => appDispatch(setPresceriptionModal(v))}
      title={content.data?.header[language]}
      contentClassName='bg-light-gray-200'
      modalWidth='!w-[600px]'
      containerClassName='z-[99]'
      language={language}
      region={region}
    >
      <div className='mt-4 flex items-center space-x-2 lg:mt-0 rtl:space-x-reverse'>
        <WarningIcon className='has-color fill-current text-orange' />
        <span className='text-custom-sm font-semibold text-orange'>
          {content.data?.label[language]}
        </span>
      </div>
      <div className='mt-6 flex flex-col-reverse items-center space-x-2 sm:flex-row rtl:space-x-reverse'>
        <div className='mt-4 sm:w-[300px] lg:mt-0'>
          <SubmitButton content={content} language={language} region={region} />

          <div className='border-t border-light-gray-300 py-6'>
            <span
              className='mb-3 block text-custom-base font-semibold text-gray-dark'
              style={{ color: content.data?.color_theme?.label_text_color }}
            >
              {content.data?.label[language]}
            </span>
            <span
              className='text-gray-dakr mb-4 block text-custom-xs'
              style={{
                color: content?.data?.color_theme?.description_text_color,
              }}
            >
              {content.data?.description[language]}
            </span>
            <ConsultButton
              content={content}
              language={language}
              region={region}
            />
          </div>
        </div>
        <Image
          src={content.data?.image_mobile}
          alt={content.data?.label[language]}
          width={0}
          height={0}
          sizes='100vw'
          className='h-[250px] w-[250px] lg:hidden'
        />

        <Image
          src={content.data?.image_web}
          alt={content.data?.label[language]}
          width={0}
          height={0}
          sizes='100vw'
          className='hidden h-[250px] w-[250px] lg:block'
        />
      </div>
    </SecondaryOptionModal>
  );
}
