import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addProduct,
  deleteAllItemsInBasket,
  getMiniBasket,
  updateProduct,
} from 'components/cart/actions';
import {
  BasketProductAvailabilitiesType,
  MiniBasketItemType,
} from 'types/cart';
import { AddProductType, UpdateProductType } from 'types/product';
import { setGlobalDeliveryWarningModal } from './modals.reducer';
import { gtmSetAddToCart, gtmSetRemoveFromCart } from 'lib/gtm';
import { getCartAvailability } from 'lib/googlemap';
import { getCookieInClient } from 'utils/cookie';
import { COOKIE_ZONE_ID } from 'utils/constant-cookies';
import { AvailabilityInformation } from 'types/store';
import { RootState } from './types';
import { LanguageCodesType, RegionCodesType } from 'utils';

type MiniBasketInitialState = {
  totalQuantity: number;
  id: string;
  items: MiniBasketItemType[];
  loading: {
    fetch: boolean;
    action: boolean;
    fetchBasketProductAvailabilities: boolean;
  };
  stock: {
    [key: string]: number;
  };
  outStockModalOpen: boolean;
  isItemOutOfStock: boolean;
  fullItemOutStock: boolean;
  isItemPartialAvailable: boolean;
  basketProductAvailabilities: BasketProductAvailabilitiesType;
};

const initialState: MiniBasketInitialState = {
  id: '',
  totalQuantity: 0,
  items: [],
  loading: {
    fetch: true,
    action: false,
    fetchBasketProductAvailabilities: false,
  },
  stock: {},
  outStockModalOpen: false,
  isItemOutOfStock: false,
  fullItemOutStock: false,
  isItemPartialAvailable: false,
  basketProductAvailabilities: {
    defaultFormat: [],
    lookUpFormat: {},
  },
};

export const addToBasketThunk = createAsyncThunk(
  'minibasket/add',
  async (
    params: {
      product: AddProductType | AddProductType[];
      language: LanguageCodesType;
      region: RegionCodesType;
    },
    { getState, rejectWithValue, dispatch }
  ) => {
    const { product, language, region } = params;
    const { basket, cmsReducer, customerReducer } = getState() as RootState;

    function openGlobalWarningModal() {
      dispatch(
        setGlobalDeliveryWarningModal({
          isOpen: true,
          type: 'only-home-delivery',
        })
      );
      return rejectWithValue({
        disableToast: true,
        message: 'Change Store Pickup or Add express Product',
      });
    }

    if (customerReducer.deliveryMode.mode === 'pickup') {
      if (Array.isArray(product)) {
        const globalProduct = product.find((item) => item.isGlobalProduct);
        if (globalProduct) {
          return openGlobalWarningModal();
        }
      } else {
        if (product.isGlobalProduct) {
          return openGlobalWarningModal();
        }
      }
    }
    if (Array.isArray(product)) {
      const skus = product.map((prd) => prd.sku); // Collect all skus from the array
      const existingProductIndexes = basket.items.filter((item) =>
        skus.includes(item.product.sku)
      );

      if (
        basket.totalQuantity >
          cmsReducer?.appSettings?.global_components?.basket?.max_total_qty ||
        (existingProductIndexes.length === 0 &&
          basket?.items?.length >=
            cmsReducer?.appSettings?.global_components?.basket
              ?.max_distinct_sku)
      ) {
        return rejectWithValue({ message: 'max_quantity_reached' });
      }
    } else {
      // Handle if product is a single item
      const productIsExist = basket.items.findIndex(
        (prd) => prd.product.sku === product?.sku
      );
      if (
        basket.totalQuantity + product.quantity >
          cmsReducer?.appSettings?.global_components?.basket?.max_total_qty ||
        (productIsExist === -1 &&
          basket?.items?.length >=
            cmsReducer?.appSettings?.global_components?.basket
              ?.max_distinct_sku)
      ) {
        return rejectWithValue({ message: 'max_quantity_reached' });
      }
    }

    const response = await addProduct(product, language, region);
    gtmSetAddToCart(product, language, region);
    if (!response) {
      return rejectWithValue({ message: 'add_to_basket_error' });
    }
    return response;
  }
);

export const getMiniBasketThunk = createAsyncThunk(
  'minibasket/get',
  async (
    params: { language: LanguageCodesType; region: RegionCodesType },
    { rejectWithValue }
  ) => {
    const { language, region } = params;
    const response = await getMiniBasket({ language, region });

    if (!response) {
      return rejectWithValue('No MiniBasket');
    }
    return response;
  }
);

export const getBasketProductAvailabilitiesThunk = createAsyncThunk<
  BasketProductAvailabilitiesType,
  { language: LanguageCodesType; region: RegionCodesType }
>(
  'minibasket/getBasketProductAvailabilities',
  async (
    params: { language: LanguageCodesType; region: RegionCodesType },
    { rejectWithValue, getState, dispatch }
  ) => {
    const { language, region } = params;
    const state = getState() as RootState;
    const cart = state.basket.items;
    const basketProducts = cart?.map((item) => ({
      sku: item.product.sku,
      quantity: item.quantity,
    }));

    const zoneIdCookie = getCookieInClient(COOKIE_ZONE_ID) || '';
    const cartAvailability = (await getCartAvailability({
      products: basketProducts,
      zoneId: zoneIdCookie,
      language,
      region,
    })) as AvailabilityInformation[];

    if (!cartAvailability) {
      return rejectWithValue('No cartAvailability');
    }

    function findAvailability(
      availabilityData: AvailabilityInformation[],
      sku: string
    ): AvailabilityInformation | null {
      if (!availabilityData) return null;
      return availabilityData.find((product: AvailabilityInformation) =>
        product.sku === sku && typeof zoneIdCookie === 'string'
          ? zoneIdCookie?.includes(product.location)
          : zoneIdCookie === product.location
      ) as AvailabilityInformation;
    }

    const availabilityMap = {} as Record<string, AvailabilityInformation>;
    const availabilityStockMap = {} as Record<string, any | undefined>;
    basketProducts.forEach((product) => {
      const availability = findAvailability(cartAvailability, product.sku);

      if (availability) availabilityMap[product.sku] = availability;
      availabilityStockMap[product.sku] = availability?.in_stock || 0;
      return availability;
    });

    dispatch(setStockItems(availabilityStockMap));

    return {
      defaultFormat: cartAvailability,
      lookUpFormat: availabilityMap,
    };
  }
);

export const clearBasketThunk = createAsyncThunk(
  'minibasket/clear',
  async (
    params: { language: LanguageCodesType; region: RegionCodesType },
    { rejectWithValue }
  ) => {
    const { language, region } = params;
    const response = await deleteAllItemsInBasket(language, region);

    if (!response) {
      return rejectWithValue('Clear basket went wrong!');
    }
    return response;
  }
);

/**
 * (itemId, quantity) if you have itemId value, use it instead of sku
 */
export const updateBasketThunk = createAsyncThunk(
  'minibasket/update',
  async (
    params: {
      product:
        | { sku: string; quantity: number }
        | { itemId: string; quantity: number }
        | UpdateProductType[];
      language: LanguageCodesType;
      region: RegionCodesType;
    },
    { getState, rejectWithValue }
  ) => {
    const { product, language, region } = params;
    const state = getState() as RootState;
    let body;
    if (Array.isArray(product)) {
      body = product;
    } else {
      const requestId =
        'itemId' in product
          ? product.itemId
          : state.basket.items.find((item) => item.product.sku === product.sku)
              ?.id;
      if (!requestId) {
        return rejectWithValue('Item is not in the basket');
      }
      body = {
        id: requestId,
        quantity: product.quantity,
      };
    }

    const response = await updateProduct(body, language, region);
    gtmSetRemoveFromCart(product, language, region, state.basket);

    if (!response?.id) {
      if (response?.errorMessage) {
        return rejectWithValue({ message: response?.errorMessage });
      }
      return rejectWithValue('Middleware Update Basket Error!!');
    }

    return response;
  }
);

const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    removeItemfromBasket(state, action: { payload: string }) {
      const itemId = action.payload;
      const itemIndex = state.items.findIndex((item) => item.id === itemId);

      if (itemIndex !== -1) {
        const removedItem = state.items[itemIndex];
        if (removedItem) {
          state.items.splice(itemIndex, 1);
          state.totalQuantity -= removedItem.quantity;
        }
      }
    },
    setStockItems(state, action: { payload: { [key: string]: number } }) {
      state.stock = action.payload;

      const isOutStock = Object.values(action.payload).some(
        (stock) => stock === 0
      );
      state.isItemOutOfStock = isOutStock;

      const allOutOfStock = Object.values(action.payload).every(
        (stock) => stock === 0
      );
      state.fullItemOutStock = allOutOfStock;
    },
    setOutStockModal: (state, action) => {
      state.outStockModalOpen = action.payload;
    },
    setIsPartialAvailable: (state, action) => {
      state.isItemPartialAvailable = action.payload;
    },
    toggleActionLoading: (state, action: PayloadAction<boolean>) => {
      state.loading.action = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMiniBasketThunk.pending, (state) => {
      state.loading.fetch = true;
    });
    builder.addCase(getMiniBasketThunk.rejected, (state) => {
      state.loading.fetch = false;
    });
    builder.addCase(getMiniBasketThunk.fulfilled, (state, action) => {
      const { id, totalQuantity, items } = action.payload;
      state.id = id;
      state.totalQuantity = totalQuantity;
      state.items = items;
      state.loading.fetch = false;
    });

    builder.addCase(getBasketProductAvailabilitiesThunk.pending, (state) => {
      state.loading.fetchBasketProductAvailabilities = true;
    });
    builder.addCase(getBasketProductAvailabilitiesThunk.rejected, (state) => {
      state.loading.fetchBasketProductAvailabilities = false;
    });
    builder.addCase(
      getBasketProductAvailabilitiesThunk.fulfilled,
      (state, action) => {
        state.basketProductAvailabilities = action.payload;
      }
    );

    builder.addCase(addToBasketThunk.pending, (state) => {
      state.loading.action = true;
    });
    builder.addCase(addToBasketThunk.rejected, (state) => {
      state.loading.action = false;
    });
    builder.addCase(addToBasketThunk.fulfilled, (state, action) => {
      if (action.payload) {
        state.id = action.payload.id;
        state.totalQuantity = action.payload.totalQuantity;
        state.items = action.payload.items;
        state.loading.action = false;
      }
    });
    builder.addCase(updateBasketThunk.pending, (state) => {
      state.loading.action = true;
    });
    builder.addCase(updateBasketThunk.rejected, (state) => {
      state.loading.action = false;
    });
    builder.addCase(updateBasketThunk.fulfilled, (state, action) => {
      if (action.payload) {
        state.id = action.payload.id;
        state.totalQuantity = action.payload.totalQuantity;
        state.items = action.payload.items;
        state.loading.action = false;
      }
    });
    builder.addCase(clearBasketThunk.pending, (state) => {
      state.loading.action = true;
    });
    builder.addCase(clearBasketThunk.rejected, (state) => {
      state.loading.action = false;
    });
    builder.addCase(clearBasketThunk.fulfilled, (state) => {
      state.totalQuantity = 0;
      state.items = [];
      state.loading.action = false;
    });
  },
});

export const {
  removeItemfromBasket,
  setStockItems,
  setOutStockModal,
  setIsPartialAvailable,
  toggleActionLoading,
} = basketSlice.actions;

export default basketSlice.reducer;
