import { CountryType } from 'types/country';
import {
  IAddressData,
  INearByStore,
  INearByZone,
} from 'types/shipping-method-switcher';
import {
  CartAvailabilityListResponse,
  CartAvailabilityResponse,
  StockInformationRequest,
} from 'types/store';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { HttpConnection } from 'utils/abstract-http';
import { COOKIE_ZONE_ID } from 'utils/constant-cookies';
import { createCookieOptions } from 'utils/cookie';

export async function saveAddressData<T>(
  body: IAddressData,
  language: LanguageCodesType,
  region: RegionCodesType
): Promise<T | null> {
  const url = '/api/auth/customer/address/add';
  const data = await HttpConnection.post<T>({
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...body }),
    params: {
      language,
      region,
    },
  });

  return data;
}

export async function removeAddress<T>(
  id: string,
  language: LanguageCodesType,
  region: RegionCodesType
) {
  const url = `/api/auth/customer/address/delete?id=${id}&language=${language}&region=${region}`;
  const result = await fetch(url, {
    method: 'DELETE',
  });
  const data: T = await result.json();
  return data;
}

export async function updateAddress<T>(
  body: IAddressData,
  language: LanguageCodesType,
  region: RegionCodesType
): Promise<T | null> {
  const url = `/api/auth/customer/address/edit?language=${language}&region=${region}`;
  const result = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
  const data: T = await result.json();
  return data;
}

export async function getAddressList<T>(
  language: LanguageCodesType,
  region: RegionCodesType
): Promise<T | null> {
  const url = `/api/auth/customer/address/list?language=${language}&region=${region}`;
  const result = await fetch(url);
  const data: T = await result.json();
  return data;
}

export async function getStoresWithinRadius(
  lng: number,
  lat: number,
  radius = process.env.NEXT_PUBLIC_NEAR_BY_PLACES_RADIUS,
  language: LanguageCodesType,
  region: RegionCodesType,
  skus?: string
): Promise<INearByStore[]> {
  radius;
  const url = `/api/auth/customer/address/store/cordinate?lng=${lng}&lat=${lat}&radius=${radius}&language=${language}&region=${region}&skus=${skus}`;
  try {
    const result = await fetch(url);

    const data: INearByStore[] = await result.json();

    if (!Array.isArray(data)) return [];
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function writeZoneIdToCookie(zones: string) {
  setCookie(COOKIE_ZONE_ID, zones, 365);
}

export async function getNearByZoneIdByCordinate(
  lng: number,
  lat: number,
  language: LanguageCodesType,
  region: RegionCodesType
): Promise<INearByZone> {
  const url = `/api/auth/customer/address/store/get-zone?lng=${lng}&lat=${lat}&language=${language}&region=${region}`;
  const result = await fetch(url);
  const data: INearByZone = await result.json();

  if (data?.zone_id) {
    setCookie(COOKIE_ZONE_ID, data.zone_id ?? '', 365);
  } else {
    deleteCookie(COOKIE_ZONE_ID);
  }
  return data;
}

function setCookie(name: string, value: string, days: number): void {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  const { path, sameSite } = createCookieOptions({
    name,
    value,
  });

  const cookieString = `${name}=${value}; expires=${date.toUTCString()};  path=${path}; secure; HttpOnly;  SameSite=${sameSite};`;

  document.cookie = cookieString;
}

export async function getCountries(
  language: LanguageCodesType,
  region: RegionCodesType
): Promise<CountryType[]> {
  const url = `/api/auth/customer/address/store/address?language=${language}&region=${region}`;
  const result = await fetch(url);
  const data: CountryType[] = await result.json();

  return data;
}

export async function getStoresWithinDistrict(
  district_id: string,
  language: LanguageCodesType,
  region: RegionCodesType,
  skus?: string
): Promise<INearByStore[]> {
  const url = `/api/auth/customer/address/store/district?district_id=${district_id}&language=${language}&region=${region}&skus=${skus}`;
  // const url = `api/auth/customer/address/store/cordinate?lng=${lng}&lat=${lat}&radius=${radius}`;
  try {
    const result = await fetch(url);

    const data: INearByStore[] = await result.json();
    if (!Array.isArray(data)) return [];

    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
}
export function deleteCookie(name: string): void {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export async function getCartAvailabilityList({
  lng,
  lat,
  radius,
  products,
  language,
  region,
}: {
  lng: number;
  lat: number;
  radius: string;
  products: StockInformationRequest[];
  language: LanguageCodesType;
  region: RegionCodesType;
}): Promise<CartAvailabilityListResponse> {
  try {
    const result = await fetch(
      `/api/cart/availability/list?lng=${lng}&lat=${lat}&radius=${radius}&language=${language}&region=${region}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(products),
      }
    );

    const data: CartAvailabilityListResponse = await result.json();
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getCartAvailability({
  products,
  zoneId,
  language,
  region,
}: {
  products: StockInformationRequest[];
  zoneId: string;
  language: LanguageCodesType;
  region: RegionCodesType;
}): Promise<CartAvailabilityResponse> {
  try {
    const result = await fetch(
      `/api/cart/availability?zoneId=${zoneId}&language=${language}&region=${region}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(products),
      }
    );

    const data: CartAvailabilityResponse = await result.json();
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getNearbyPharmacies(
  lng: number,
  lat: number,
  radius = process.env.NEXT_PUBLIC_NEAR_BY_PLACES_RADIUS,
  language: LanguageCodesType,
  region: RegionCodesType
): Promise<INearByStore[]> {
  radius;
  const url = `/api/auth/customer/address/store/cordinate?lng=${lng}&lat=${lat}&radius=${radius}&language=${language}&region=${region}`;
  const result = await fetch(url);

  const data: INearByStore[] = await result.json();
  return data;
}
