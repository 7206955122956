import { StarIcon } from '@heroicons/react/24/solid';
import ProductCartButton from 'components/buttons/product-cart-button';
import Image from 'next/image';
import { Link } from 'components/link';
import { ExpressIcon } from 'components/icons/express';
import { ProductItemType } from 'types/cms/product-carousel';
import { LanguageCodesType, RegionCodesType, getPdpUrl } from 'utils';
import ResponsiveImage from 'components/responsive-image';
import BannerLoadingSkeleton from 'components/banner-loading-skeleton';
import { GlobalIcon } from 'components/icons/global-icon';

export default function BundleProductCarouselItem({
  product,
  rates,
  maxQuantityLimit,
  language,
  region,
}: {
  product: ProductItemType | undefined;
  rates: {
    avgRating: number;
    totalRatings: number;
  };
  maxQuantityLimit: number;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  if (!product || !product?.attributes)
    return <BannerLoadingSkeleton className='!h-[530px] lg:h-[530px]' />;

  const { max_orderable_quantity, min_orderable_quantity } = product.attributes;

  const deliverType = () => {
    if (product.shipment_type === 'express') {
      return (
        <div
          className='flex h-[21px] items-center space-x-1 rounded-3xl bg-red px-2 py-1 rtl:space-x-reverse'
          style={{ backgroundColor: 'rgba(65, 193, 138, 1)' }}
        >
          <ExpressIcon className='h-[12px] w-[15.692px] text-white' />
          <span className='text-[11px] font-bold text-white'>Express</span>
        </div>
      );
    } else if (product.shipment_type === 'global')
      return <GlobalIcon className='h-[21px] text-white' />;
  };

  return (
    <div>
      <div
        className={
          'relative flex justify-around space-x-4 rounded-lg bg-white p-2 shadow-sm rtl:space-x-reverse'
        }
      >
        <div className='relative'>
          <div className='absolute z-10'>
            <Image
              src='/assets/images/product_tags/bundle_offer.png'
              alt={'bundle product'}
              width={0}
              height={0}
              sizes='100vw'
              className='h-[25px] w-[70px]'
            />
          </div>

          {product?.attributes?.media_gallery ? (
            <Link
              href={getPdpUrl(
                product.sku_id,
                product.attributes?.calculated_relative_url,
                language,
                region
              )}
              className='flex h-[106px] w-[101px] lg:h-[138px] lg:w-[130px]'
            >
              <ResponsiveImage
                imageAlt={product.attributes?.name}
                productImage={product?.attributes?.media_gallery[0]?.types}
                imageClassName='mx-auto h-full w-full object-contain'
              />
            </Link>
          ) : (
            <div className='mx-auto h-[142px] w-[160px] lg:h-[210px] lg:w-[200px]'></div>
          )}
        </div>
        <div>
          <div className='flex flex-col items-start'>
            <Link
              href={getPdpUrl(
                product.sku_id,
                product.attributes?.calculated_relative_url,
                language,
                region
              )}
            >
              <div className='flex w-full items-center justify-between'>
                <div className='flex items-center space-x-1 ltr:right-3 rtl:left-3 rtl:space-x-reverse'>
                  {rates?.avgRating?.toFixed(1) &&
                    Number(rates?.avgRating?.toFixed(1)) > 0 && (
                      <div className='flex items-center space-x-1 rtl:space-x-reverse'>
                        <StarIcon className='h-4 w-4 text-primary-yellow' />
                        <div className='flex items-center space-x-1 text-custom-xs font-bold text-gray rtl:space-x-reverse'>
                          <span>{rates.avgRating.toFixed(1)}</span>
                          <span className='hidden sm:block'>
                            ({rates?.totalRatings})
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <span className='my-3 block text-custom-base ltr:pr-12 rtl:pl-12'>
                {product.attributes?.name}
              </span>

              <div className='flex space-x-1 max-lg:flex-col lg:items-center rtl:space-x-reverse'>
                {product?.attributes?.price ==
                product?.attributes?.price_after_discount ? (
                  <>
                    <span className='text-custom-lg font-semibold text-gray-dark'>
                      {product?.attributes?.price} {product.attributes.currency}
                    </span>
                    <span className='h-4 text-sm'></span>
                  </>
                ) : (
                  <>
                    <span className='text-custom-lg font-semibold text-red'>
                      {product?.attributes?.price_after_discount}{' '}
                      {product.attributes.currency}
                    </span>
                    <span className='text-sm font-semibold text-gray-dark line-through'>
                      {product?.attributes?.price} {product.attributes.currency}
                    </span>
                  </>
                )}
              </div>
            </Link>
          </div>
          <div className='mt-5 flex items-center justify-between'>
            {deliverType()}

            <ProductCartButton
              product={{
                sku: product.sku_id,
                isGlobalProduct: product.shipment_type === 'global',
              }}
              maximumQuantity={
                maxQuantityLimit
                  ? max_orderable_quantity > maxQuantityLimit ||
                    max_orderable_quantity === 0
                    ? maxQuantityLimit
                    : max_orderable_quantity
                  : max_orderable_quantity
              }
              minimumQuantity={min_orderable_quantity}
              language={language}
              region={region}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
