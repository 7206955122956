'use client';

import React from 'react';
import { useTranslation } from 'app/i18n/client';
import clsx from 'clsx';
import Image from 'next/image';
import { Link } from 'components/link';
import { PlusIcon } from 'components/icon';
import { convertToAbsoluteURL, getPdpUrl } from 'utils';
import BaseSwiper from 'components/swiper/base-swiper';
import { BundleProductInfoType } from 'types/product';
import BannerLoadingSkeleton from 'components/banner-loading-skeleton';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export default function BundleItem({
  bundle,
  currency,
  titleClassName,
  language,
  region,
}: {
  bundle: BundleProductInfoType;
  currency: string;
  titleClassName?: string;
  priceClassName?: string;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'product-detail-page'
  );
  if (!bundle || !bundle?.items || bundle?.items?.length === 0) return;

  const worthPrice = bundle?.regular_price;
  const nowPrice = bundle?.new_price;
  const discount = bundle?.discount;
  const bundleItems = bundle?.items;

  if (!bundle)
    return <BannerLoadingSkeleton className='!lg:h-[300px] !h-[300px]' />;

  return (
    <>
      <div className='flex flex-col items-start space-y-2'>
        <div className='flex w-full items-center justify-between space-x-4'>
          <BaseSwiper
            swiperProps={{
              slidesPerView: bundleItems?.length > 2 ? 2 : bundleItems?.length,
              spaceBetween: 60,
              navigation: true,
            }}
          >
            {bundleItems?.map((product, index: number) => (
              <React.Fragment key={`bundle-item-product-${index}`}>
                <div className='relative'>
                  <Link
                    href={getPdpUrl(
                      product.sku_id,
                      product.attributes?.calculated_relative_url,
                      language,
                      region
                    )}
                    className='flex flex-col'
                  >
                    {product.attributes?.media_gallery?.types?.medium && (
                      <div className='relative flex justify-center'>
                        <Image
                          src={convertToAbsoluteURL(
                            product.attributes?.media_gallery?.types.medium
                          )}
                          alt={product.attributes?.name}
                          width={0}
                          height={0}
                          sizes='100vw'
                          className='mx-auto h-[106px] w-[101px] object-contain lg:h-[137px] lg:w-[131px]'
                        />
                      </div>
                    )}
                    <span
                      className={clsx(
                        'mt-3 line-clamp-3 h-12 text-center',
                        'text-custom-sm leading-[1.3] text-gray-dark ltr:text-left rtl:text-right',
                        titleClassName
                      )}
                    >
                      {product.attributes.name}
                    </span>
                  </Link>
                  {index % 2 === 0 && (
                    <div className='absolute top-[30%] translate-y-1/2 ltr:-right-8 rtl:-left-8'>
                      <PlusIcon className='h-[15px] w-[15px] fill-primary-blue stroke-2' />
                    </div>
                  )}
                </div>
              </React.Fragment>
            ))}
          </BaseSwiper>
        </div>
        <div className='h-[1px] w-full bg-black opacity-[0.05]' />
        <div className='flex w-full flex-col items-center space-x-1 rtl:space-x-reverse'>
          {worthPrice && (
            <span className='text-custom-base leading-[1.3] text-gray'>
              {t('bundle.worth')} {parseFloat(worthPrice).toFixed(2)} {currency}
            </span>
          )}
          {nowPrice && (
            <span className='mt-1 text-[20px] font-semibold leading-[1.3] text-primary-red'>
              {t('bundle.now')} {parseFloat(nowPrice).toFixed(2)} {currency}
            </span>
          )}
          {discount && (
            <span className='mt-2 rounded-full border border-dashed border-primary-red bg-primary-red/[0.1] px-2 py-1 font-semibold leading-[1.3] text-primary-red max-lg:text-custom-xs lg:text-custom-lg'>
              {t('bundle.save')} {parseFloat(discount)} {currency}
            </span>
          )}
        </div>
      </div>
    </>
  );
}
