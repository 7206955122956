import { REVALIDATE } from "lib/constants-cache";

export function isOnEdgeRuntime(): boolean {
  // @ts-ignore
  return typeof EdgeRuntime === 'string';
}

export function fetchCacheConverter({
  cache,
  revalidate,
}: {
  cache?: boolean;
  revalidate?: number;
}): {
  cf?: {
    cacheEverything: boolean;
    cacheTtl?: number;
  };
  cache?: RequestCache;
  next?: {
    revalidate: number;
  };
} {
  if (isOnEdgeRuntime()) {
    return {
      cf: {
        cacheEverything:
          typeof cache === 'boolean' && cache === false ? false : true,
        ...(typeof cache === 'boolean' && cache === false
          ? {}
          : { cacheTtl: revalidate || REVALIDATE.fiveMinutes_300 }),
      },
    };
  }

  return {
    ...(!revalidate && { cache: 'force-cache' }),
    ...(typeof cache === 'boolean' && cache === false && { cache: 'no-store' }),
    ...(revalidate && {
      next: { revalidate },
    }),
  };
}
