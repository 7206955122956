export const COOKIE_BASKET_ID = 'basketId';
export const COOKIE_CUSTOMER_TOKEN = 'customerToken';
export const COOKIE_CUSTOMER_REFRESH_TOKEN = 'customerRefreshToken';
export const COOKIE_ZONE_ID = 'zoneId';
export const COOKIE_CUSTOMER_NUHDEEK_ID = 'customerNuhdeekId';
export const COOKIE_MAGENTO_CUSTOMER_ID = 'magento_customer_id';
export const COOKIE_IMPERSONATE = 'impR';
export const COOKIE_ROUTABLE_STORE_ID = 'routableStoreId';
export const COOKIE_CONSTENT_PROMO = 'consentPromo';
export const COOKIE_CONSTENT_PRIME = 'consentPrime';
export const COOKIE_POPUP_ERX_ORDER_CONFIRMATION = 'popup_erxOrderConfirmation';
export const COOKIE_POPUP_ORDER_CONFIRMATION = 'popup_orderConfirmation';
export const COOKIE_POPUP_REGISTRATION_CONFIRMATION =
  'popup_registrationConfirmation';
export const COOKIE_DY_ID_SERVER = '_dyid_server';
export const COOKIE_DY_SESSION = '_dyjsession';
