/**
 * @description extract the error message from the error instance
 * @param error exception message instance
 * @returns extract the right exception type from the request or return a fallback of 'UKNOWN_ERROR
 */
export const extractExceptionMessage = (error: any) => {
  return (
    (error as { error: string })?.error ||
    (error as { message: string })?.message ||
    'UNKNOWN_ERROR'
  );
};
